









































import mixins from 'vue-typed-mixins'
import Navigation from '@/builder/sections/mixins/Navigation'
export default mixins(Navigation).extend({
  name: 'Footer1',
  components: {
    AppFooterContact: () => import('./components/AppFooterContact.vue'),
    AppFooterMenu: () => import('./components/AppFooterMenu.vue'),
    AppFooterAccount: () => import('./components/AppFooterAccount.vue'),
    AppFooterSignature: () => import('./components/AppFooterSignature.vue')
  }
})
